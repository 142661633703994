<template>
  <div>
    <v-sheet class="d-flex gap-10">
      <v-sheet class="px-5 py-5" elevation="3" width="250">
        <v-chip color="success" class="mr-3">{{records.gaps_count}}</v-chip>
        <div class="mt-2">{{$t('dispo.gaps')}}</div>
      </v-sheet>
      <v-sheet class="px-5 py-5" elevation="3" width="250">
        <v-chip color="success" class="mr-3">{{records.unassigned_job_posts_count}}</v-chip>
        <div class="mt-2">{{$t('dispo.not-assigned-jobs')}}</div>
      </v-sheet>
      <v-sheet class="px-5 py-5" elevation="3" width="250">
        <v-chip color="success" class="mr-3">{{records.draft_count}}</v-chip>
        <div class="mt-2">{{$t('dispo.not-assigned-rotations')}}</div>
      </v-sheet>
      <v-sheet class="px-5 py-5" elevation="3" width="250">
        <v-chip color="success" class="mr-3">{{records.ending_contract_count}}</v-chip>
        <div class="mt-2">{{$t('dispo.ending-contract')}}</div>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import dispo from "@/api/modules/dispo"

export default {
  data: function () {
    return {
      records: {}
    }
  },
  created() {
    this.fetchRecords();
  },
  computed: {
    ...mapGetters('auth', ['role']),
  },
  methods: {
     fetchRecords() {
       dispo.fetchDispo()
        .then((res) => {
          this.records = res.data
        })
    }
  }
}
</script>